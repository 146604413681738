import { CANINE_COLORS, CANINE_GENERATION_NAMES, CanineColorNames, Canines } from '$shared/constants/canines';
import { getAddress } from '$shared/domain/constants';
import { Canine, EnrichedCanine } from '$shared/types/cache-api';
import CommonAnimalsService from '../animals';

const CANINES_BREEDER_DAPP_ADDRESS = getAddress('CANINES_BREEDER_DAPP');
const CANINES_INCUBATOR_DAPP_ADDRESS = getAddress('CANINES_INCUBATOR_DAPP');
const CANINES_FARMING_DAPP_ADDRESS = getAddress('CANINES_FARMING_DAPP');

abstract class CommonCaninesService extends CommonAnimalsService {
    ANIMAL_ACRONYM = 'CANI';

    ANIMAL_PATH = 'canines';

    ANIMAL_CONSTANT = Canines;

    ANIMAL_COLORS = CANINE_COLORS;

    ANIMAL_COLORS_NAMES = CanineColorNames;

    ANIMAL_GENERATION_NAMES = CANINE_GENERATION_NAMES;

    INCUBATOR_DAPP_ADDRESS = CANINES_INCUBATOR_DAPP_ADDRESS;

    BREEDER_DAPP_ADDRESS = CANINES_BREEDER_DAPP_ADDRESS;

    FARMING_DAPP_ADDRESS = CANINES_FARMING_DAPP_ADDRESS;

    isAnimal = <T extends { name: string }>({ name }: T) => name.indexOf('CANI-') === 0;

    addFarmingProduction = async <T extends { oldRarity: number; assetId: string; name: string }>(
        canines: Canine[],
    ): Promise<Array<EnrichedCanine>> => {
        const caninesWithEggProduction = canines.map(async (canine) => {
            const { estimatedBasePower, global, toClaim, globalInterest, assetInterest, claimed } =
                await this.farmingService.fetchFarmingPower(canine.assetId, canine.name, canine.basePower);

            const farmingPower = Math.floor(
                ((canine.basePower ?? (estimatedBasePower as number)) / 100) * Math.floor(canine.oldRarity),
            );
            const eggProduction = this.calcFarmingProduction(farmingPower, global);

            return {
                ...canine,
                eggProduction,
                farmingParams: {
                    farmingPower,
                    globalFarmingPower: global,
                    stakedBefore: !!canine.basePower,
                    toClaim,
                    globalInterest,
                    assetInterest,
                    claimed,
                },
            };
        });
        return Promise.all(caninesWithEggProduction);
    };
}

export default CommonCaninesService;
