export const BOXES_COUNT = 901;
export const EGG_DECIMALS = 1e8 as integer;
export const WAVES_DECIMALS = 1e8 as integer;
export const SPICE_DECIMALS = 1e8 as integer;
export const PETE_DECIMALS = 1e8 as integer;
export const DUCKLINGS_FIRST_LAP_START_TS = 1_632_484_800_000;
export const DUCKLINGS_LAP_LENGTH_TS = 21_600_000; // 6 hours
export const DUCKLINGS_NEED_TO_FEED = 172_800_000; // 48 hours
export const DUCKLING_REQUIRES_TO_EAT_AMOUNT = 0.8; // 0.8 EGG
export const SPICE_DUCKLING_REQUIRES_TO_EAT_AMOUNT = 100; // 100 SPICE
export const DUCK_INCREASE_CAPACITY_LIMIT = 1000;

export const SCALE = 10000;
export const REWARD_PER_BLOCK = 274;
