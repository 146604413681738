/* eslint-disable @typescript-eslint/no-explicit-any */
import Cache from '$shared/structures/Cache';
import CommonAuctionService from '$shared/services/auction';
import type { ICachedMarketplace } from '$shared/types';
import { EGG_DECIMALS } from '$shared/constants';
import {
    ICachedMyRentedDucksResponse,
    ICachedRentalMarketplace,
    ICachedRentalMarketplaceResponse,
} from '$shared/types/marketplaces';
import { urlString } from '$shared/utils';

import { ascend, descend, sort } from '$/utils/functional';
import { Filters } from '$/pages/MarketplaceRental/state/filters';

import helperService from '../helper';

type CachedDucks = ICachedMarketplace['ducks'];
type CachedDuck = CachedDucks[number];

const getRandom = (min, max) => Math.random() * (max - min) + min;
const getRandomBool = () => Boolean(Math.round(Math.random()));

const getStrategyMocks = () => ({
    collection: getRandomBool(),
    duckWarsCards: ['average', 'bulletproof', 'oneStep', ...Array(Math.floor(getRandom(0, 10)))],
    buyback: Math.floor(getRandom(0, 30)),
    estReward: getRandom(0, 10),
    estBuyback: getRandom(0, 30).toFixed(1),
    ...(getRandomBool() && {
        farming: {
            userHasCurrentColorPerch: getRandomBool(),
            price: Math.floor(getRandom(1, 500).toFixed(8)) * EGG_DECIMALS,
            priceWithPerch: Math.floor(getRandom(1, 500).toFixed(8)) * EGG_DECIMALS,
            farmingPower: Number(Math.random().toFixed(3)),
        },
    }),
    ...(getRandomBool() && {
        duckHunt: {
            eggsPerDay: Number(`${Math.round(Math.random())}${Math.random().toFixed(3).replace('0.', '.')}`),
            total: `${Math.floor(getRandom(0, 50))}/${Math.floor(getRandom(0, 200))}`,
        },
    }),
});

class FrontendAuctionService extends CommonAuctionService {
    protected helperService = helperService;

    private marketplaceCache = new Cache<ICachedMarketplace>({ ttl: 3000 });

    fetchCachedMarketplaceRental = async (
        userAddress: string,
        filters: Filters,
        sort: { orderBy: string; orderByAsc: boolean },
        page = 1,
        size = 10,
    ): Promise<Partial<ICachedRentalMarketplace>> => {
        try {
            const {
                data: { data: ducks, pagination },
            } = await this.helperService.http.get<ICachedRentalMarketplaceResponse>(
                urlString(`${this.helperService.API_URL}/v1/rental-place`, {
                    userAddress,
                    ...filters,
                    sort: `${sort.orderBy}:${sort.orderByAsc ? 'ASC' : 'DESC'}`,
                    page,
                    size,
                }),
            );

            const dataWithStrategy = {
                ducks: ducks.map((item: any) => ({
                    ...item,
                    strategy: {
                        estReward: item.eggProduction,
                        duckHunt: {
                            eggsPerDay: item.dailyLimit,
                            total: item.totalCapacity,
                        },
                    },
                })),
                pagination,
            };

            return dataWithStrategy;
        } catch (error) {
            const err = error as Error;
            console.error('An error occurred in method fetchCachedMarketplace:', err);
            return {};
        }
    };

    fetchCachedMarketplaceRentalMyDucks = async (userAddress: string): Promise<Partial<ICachedRentalMarketplace>> => {
        try {
            const {
                data: { data: { data: ducks, pagination } = { data: [] } },
            } = await this.helperService.http.get<ICachedMyRentedDucksResponse>(
                `${this.helperService.API_URL}/v2/addresses/${userAddress}/ducks/rentedByMe`,
            );

            const dataWithStrategy = {
                ducks: ducks.map((item: any) => ({
                    ...item,
                    strategy: {
                        estReward: item.eggProduction,
                        duckHunt: {
                            eggsPerDay: item.dailyLimit,
                            total: item.totalCapacity,
                        },
                    },
                })),
                pagination,
            };

            return dataWithStrategy;
        } catch (error) {
            const err = error as Error;
            console.error('An error occurred in method fetchCachedMarketplaceRentalMyDucks:', err.message);
            return {};
        }
    };

    fetchCachedMarketplace = async (): Promise<Partial<ICachedMarketplace>> => {
        if (this.marketplaceCache.data) {
            return this.marketplaceCache.data;
        }

        try {
            const { data } = await this.helperService.http.get<ICachedMarketplace>(
                `${this.helperService.API_URL}/v1/cache/marketplace`,
            );
            const dataWithStrategy = {
                ...data,
                ducks: data.ducks.map((item) => ({ ...item, strategy: getStrategyMocks() })),
                turtles: data.turtles.map((item) => ({ ...item, strategy: getStrategyMocks() })),
                petes: data.petes.map((item) => ({ ...item, strategy: getStrategyMocks() })),
                canines: data.canines.map((item) => ({ ...item, strategy: getStrategyMocks() })),
                felines: data.felines.map((item) => ({ ...item, strategy: getStrategyMocks() })),
            };
            this.marketplaceCache.data = dataWithStrategy;
            return dataWithStrategy;
        } catch (error) {
            const err = error as Error;
            console.error('An error occurred in method fetchCachedMarketplace:', err.message);
            return {};
        }
    };

    fetchMarketDucks = async (
        filters?: Array<(duck: CachedDuck) => boolean>,
        sortBy?: [(duck: CachedDuck) => any, 'ASC' | 'DESC'],
    ): Promise<CachedDucks> => {
        const { ducks } = await this.fetchCachedMarketplace();
        const filteredDucks: CachedDucks =
            ducks?.filter((duck) => (filters ?? []).every((filter) => filter(duck))) ?? [];

        if (sortBy) {
            const [sortField, sortDirection] = sortBy;
            const sortFn = sortDirection === 'ASC' ? ascend : descend;

            return sort(sortFn(sortField), filteredDucks);
        }

        return filteredDucks;
    };
}

export default FrontendAuctionService;
