import { useSelector } from 'react-redux';
import { useQuery, UseQueryResult } from 'react-query';

import type { Assets } from '$shared/constants';
import { ONE_MINUTE } from '$shared/constants';
import { int } from '$shared/utils';

import { authAddressSelector } from '$/store/selectors';
import pricesBalanceService from '$/services/api/prices-and-balance';

export const createUserBalanceQueryKey =
    (currency: keyof Assets) =>
    (authAddress: addressId): string[] =>
        ['user-balance', currency, authAddress];

export const userWavesBalanceQueryKey = createUserBalanceQueryKey('WAVES');
export const userEGGBalanceQueryKey = createUserBalanceQueryKey('EGG');
export const userOldEGGBalanceQueryKey = createUserBalanceQueryKey('OLD_EGG');
export const userSPICEBalanceQueryKey = createUserBalanceQueryKey('SPICE');
export const userPETEBalanceQueryKey = createUserBalanceQueryKey('PETE');
export const userSTREETBalanceQueryKey = createUserBalanceQueryKey('STREET');
export const userFOMOBalanceQueryKey = createUserBalanceQueryKey('FOMO');
export const userKOLKHOZBalanceQueryKey = createUserBalanceQueryKey('KOLKHOZ');
export const userTURTLEBalanceQueryKey = createUserBalanceQueryKey('TURTLE');
export const userLATAMBalanceQueryKey = createUserBalanceQueryKey('LATAM');
export const userEGGPOINTBalanceQueryKey = createUserBalanceQueryKey('EGGPOINT');
export const userDUXPLORERBalanceQueryKey = createUserBalanceQueryKey('DUXPLORER');

export const wavesPriceQueryKey = (): string[] => ['price', 'WAVES'];
export const eggPriceQueryKey = (): string[] => ['price', 'EGG'];

const createUserBalance =
    (queryKey: (address: addressId) => string[], getter: (address: addressId) => Promise<number>) =>
    (): UseQueryResult<wavesint> => {
        const authAddress = useSelector(authAddressSelector);

        return useQuery(queryKey(authAddress), () => getter(authAddress), { staleTime: ONE_MINUTE });
    };

const createTokenDetailBalance =
    (asset: keyof Assets, useBalance: () => UseQueryResult<integer>, usePrice?: () => UseQueryResult<number>) => () => {
        const { data: balance = int(0), isLoading: isLoadingBalance } = useBalance();
        const { data: price = int(0), isLoading: isLoadingPrice } = usePrice?.() ?? { data: int(0), isLoading: true };

        return {
            data: pricesBalanceService.getTokenDetail(asset)(balance, price),
            isLoading: isLoadingBalance || isLoadingPrice,
        };
    };

export const useWavesBalance = createUserBalance(userWavesBalanceQueryKey, pricesBalanceService.getWavesBalance);

export const useEggBalance = createUserBalance(userEGGBalanceQueryKey, pricesBalanceService.getEggsBalance);

export const useOldEggBalance = createUserBalance(userOldEGGBalanceQueryKey, pricesBalanceService.getOldEggsBalance);

export const useSpiceBalance = createUserBalance(userSPICEBalanceQueryKey, pricesBalanceService.getSpicesBalance);

export const usePeteBalance = createUserBalance(userPETEBalanceQueryKey, pricesBalanceService.getPeteBalance);

export const useWavesPrice = (): UseQueryResult<number> =>
    useQuery(wavesPriceQueryKey(), pricesBalanceService.getWavesPrice, { staleTime: ONE_MINUTE });

export const useEggPrice = (): UseQueryResult<number> =>
    useQuery(eggPriceQueryKey(), pricesBalanceService.getEggPrice, { staleTime: ONE_MINUTE });

export const useEGGTokenDetailsBalance = createTokenDetailBalance('EGG', useEggBalance, useEggPrice);
