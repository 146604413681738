import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './styles/bootstrap.scss';
import './styles/app.scss';

import { isWebpSupported } from '$/browser/webp';
import useAppConfig from '$/hooks/useAppConfig';
import useDynamicVh from '$/hooks/useDynamicVh';
import NewsModal from '$/components/modals/NewsModal';
import CharityFirstJuneModal from '$/components/modals/CharityFirstJuneModal';
import history from '$/services/history';
import UnderConstructionPage from '$/pages/UnderConstructionPage';

import '$/styles/global.css';
import useEventStyles from './hooks/features/useEventStyles';
import Routes from './Routes';
import './Vendor';

const App = (): JSX.Element => {
    useDynamicVh();
    useEventStyles();

    const isUnderConstruction = useAppConfig('DEV', 'DISABLE_UI');

    useEffect(() => {
        isWebpSupported();
    }, []);

    if (isUnderConstruction) {
        return <UnderConstructionPage />;
    }

    return (
        <Router history={history}>
            <Routes />
            <NewsModal />
            <CharityFirstJuneModal />
            <ToastContainer position="top-right" />
        </Router>
    );
};

export default App;
