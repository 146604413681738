import { capitalize, int, urlString } from '../../utils';
import { getKeyPart } from '../../domain/contract-data';
import Cache from '../../structures/Cache';
import AbstractDataFetcher from '../AbstractDataFetcher';
import {
    Claimed,
    FarmingEntries,
    FarmingGlobals,
    FarmingGlobalsV2,
    FarmingPower,
    LastCheckInterest,
    OriginalCaller,
    Owner,
} from './data-types';
import { IFarmingDetailsV2, IFarmingEntriesV2, IFarmingGlobalsV2, IFarmingParamsV2 } from '$shared/types/farms';
import { Canine, EnrichedCanine, EnrichedFeline, Feline } from '$shared/types/cache-api';
import { IAnimalDetailsV2 } from '$shared/types/animals';

abstract class CommonAnimalsFarmingService extends AbstractDataFetcher {
    protected abstract DAPP_ADDRESS: addressId;
    abstract FARMING_ITEM_NAME: string;
    private farmingGlobalsCache = new Cache<IFarmingGlobalsV2>({ ttl: 5_000 });

    private addEntry = (
        record: IFarmingEntriesV2,
        paramName: keyof IFarmingEntriesV2 | 'without' | undefined | string,
        value: string | integer | boolean | number,
    ) => {
        if (value == null || paramName === 'without') {
            return record;
        }
        if (record === undefined) {
            return null;
        }
        if (paramName === 'perchColor') {
            record[paramName] = typeof value === 'string' ? value : value.toString();
            return record;
        }

        if (typeof value === 'number' && paramName) {
            record[paramName] = value;
        }

        return record;
    };

    fetchAnimalOwnerForFarming = async (animalId: animalId) => {
        const owner = await this.fetchDataByKey<Owner>(`${animalId}_owner`);
        return owner?.value;
    };

    addFarmingProduction = async <T extends { oldRarity: number; assetId: string; name: string }>(
        animal: Feline[] | Canine[],
    ): Promise<Array<EnrichedFeline | EnrichedCanine>> => {
        const animalWithEggProduction = animal.map(async (animal) => {
            const { estimatedBasePower, global, toClaim, globalInterest, assetInterest, claimed } =
                await this.fetchFarmingPower(animal.assetId, animal.name, animal.basePower);

            if (!animal.basePower) {
                const estimatedFarmingPower = Math.floor(
                    Math.floor(
                        ((animal.basePower ?? (estimatedBasePower as number)) / 100) * Math.floor(animal.oldRarity),
                    ),
                );
                return {
                    ...animal,
                    farmingParams: {
                        estimatedFarmingPower,
                        lastCheckFarmedAmount: globalInterest,
                        globalFarmingPower: global,
                        stakedBefore: !!animal.basePower,
                        toClaim,
                        claimed,
                    },
                };
            } else {
                const farmingPower = Math.floor((Math.floor(animal.basePower) * Math.floor(animal.oldRarity)) / 100);
                return {
                    ...animal,
                    farmingPower,
                    farmingParams: {
                        lastCheckFarmedAmount: globalInterest,
                        globalFarmingPower: global,
                        stakedBefore: !!animal.basePower,
                        toClaim,
                        claimed,
                    },
                };
            }
        });
        return Promise.all(animalWithEggProduction);
    };

    addFarmingProductionOne = async <T extends { oldRarity: number; assetId: string; name: string }>(
        animal: IAnimalDetailsV2,
    ): Promise<IAnimalDetailsV2> => {
        const { estimatedBasePower, global, toClaim, globalInterest, claimed } = await this.fetchFarmingPower(
            animal.assetId,
            animal.name,
            animal.basePower,
        );
        if (!animal.basePower) {
            const estimatedFarmingPower = Math.floor(
                Math.floor(((animal.basePower ?? (estimatedBasePower as number)) / 100) * Math.floor(animal.oldRarity)),
            );
            return {
                ...animal,
                farmingParams: {
                    estimatedFarmingPower,
                    lastCheckFarmedAmount: globalInterest,
                    globalFarmingPower: global,
                    stakedBefore: !!animal.basePower,
                    toClaim,
                    claimed,
                },
            };
        } else {
            const farmingPower = Math.floor((Math.floor(animal.basePower) * Math.floor(animal.oldRarity)) / 100);
            return {
                ...animal,
                farmingPower,
                farmingParams: {
                    lastCheckFarmedAmount: globalInterest,
                    globalFarmingPower: global,
                    stakedBefore: !!animal.basePower,
                    toClaim,
                    claimed,
                },
            };
        }
    };
    //TODO: we should refactor this and fully use the cache service to determine the ducks, not go to the database itself anymore.
    //TODO: this is not very performant, especially since we have got all the ducks in the database
    fetchAnimalByAddress: (address: addressId) => Promise<FarmingEntries[]> = async (address: addressId) => {
        const farmingAnimals = await this.fetchDataMatch<OriginalCaller>(`.*_original_caller`);
        const animals = farmingAnimals.filter(({ value }) => value === address).map(({ key }) => key.split('_')[0]);
        let entries: FarmingEntries[] = [];
        for (const animal of animals) {
            const matches = await this.fetchDataMatch<FarmingEntries>(`address_.*_asset_${animal}.*?`);
            entries = [...entries, ...matches];
        }
        return entries;
    };

    fetchFarmingEntriesOfAnimal = async (animalId: animalId, address: addressId) => {
        const farmingPower = await this.fetchDataMatch<FarmingPower>(
            `address_${address}_asset_${animalId}_farmingPower`,
            { avoidCache: true },
        );
        const claimed = await this.fetchDataMatch<Claimed>(`${address}_asset_${animalId}_claimed`, {
            avoidCache: true,
        });
        const lastCheckInterest = await this.fetchDataMatch<LastCheckInterest>(
            `address_${address}_asset_${animalId}_lastCheckInterest`,
            { avoidCache: true },
        );
        return [farmingPower[0], claimed[0], lastCheckInterest[0]];
    };

    fetchFarmingEntriesOnAddress = (address: addressId) =>
        this.fetchDataMatch<FarmingEntries>(`assetId_.*?_owner_${address}_.*?`, { avoidCache: true });

    fetchAnimalFarmingEntriesOnAddress = (address: addressId) =>
        this.fetchDataMatch<FarmingEntries>(`address_${address}_asset_.*?`, { avoidCache: true });

    fetchFarmingPower = async (
        animalId: animalId,
        name: string,
        basePower: number | undefined,
    ): Promise<{
        production: number;
        stakedBefore: boolean;
        exactFp: number;
        estimatedBasePower?: number;
        global: number;
        toClaim: number;
        globalInterest: number;
        assetInterest: number;
        claimed: number;
    }> => {
        let farmingPower: Array<FarmingGlobalsV2>;
        farmingPower = await this.fetchDataByKeys<FarmingGlobalsV2>([`total_staked`, 'global_lastCheck_interest']);
        //Optimialisation; query it 1 time outside the method, and then pass it to the method
        const global = farmingPower.find((entry) => entry.key === 'total_staked')?.value;
        const globalInterest = farmingPower.find((entry) => entry.key === 'global_lastCheck_interest')?.value;
        const data = await this.fetchAnimalOwnerForFarming(animalId);
        if (basePower && data) {
            const assetInfo = await this.fetchDataByKeys<LastCheckInterest | FarmingPower | Claimed>([
                `address_${data}_asset_${animalId}_lastCheckInterest`,
                `address_${data}_asset_${animalId}_farmingPower`,
                `${data}_asset_${animalId}_claimed`,
            ]);
            const assetInterest = assetInfo.find(
                (entry) => entry.key === `address_${data}_asset_${animalId}_lastCheckInterest`,
            )?.value;
            const fullFP = assetInfo.find(
                (entry) => entry.key === `address_${data}_asset_${animalId}_farmingPower`,
            )?.value;
            const claimed = assetInfo.find((entry) => entry.key === `${data}_asset_${animalId}_claimed`)?.value;
            const toClaim = this.calculateToClaim({
                currentInterest: globalInterest ?? int(0),
                assetLastCheckInterest: assetInterest,
                farmingPower: fullFP ?? int(0),
            });
            return {
                stakedBefore: true,
                global: global || 0,
                production: 0,
                toClaim: toClaim,
                globalInterest: globalInterest || 0,
                assetInterest: assetInterest || 0,
                exactFp: fullFP || 0,
                claimed: claimed || 0,
            };
        } else {
            const height = await this.helperService.getBlockchainHeight();
            const multplier = ((height - 3750000) * 100) / (60 * 24 * 30 * 3);
            const uniqueGenes = name.slice(-1) == 'U' ? 8 : new Set(name.split('-')[1]).size;
            const output = (((Math.pow(1.5, uniqueGenes) * 100 * multplier) / 100) * 99) / 100;
            return {
                estimatedBasePower: output,
                stakedBefore: false,
                global: global || 0,
                production: 0,
                toClaim: 0,
                globalInterest: globalInterest || 0,
                assetInterest: 0,
                exactFp: 0,
                claimed: 0,
            };
        }
    };

    fetchFarmingDetails = async (animalId: animalId, owner: addressId): Promise<Partial<IFarmingDetailsV2>> => {
        const [farmingEntries, totalValues] = await Promise.all([
            this.fetchFarmingEntriesOfAnimal(animalId, owner),
            this.fetchFarmingGlobals(),
        ]);

        const farmingEntriesByKeys = farmingEntries.reduce((result: IFarmingEntriesV2 | null, { key, value }) => {
            let nameKey: 'farmingPower' | 'lastCheckInterest' | undefined | string = getKeyPart(key, 4);

            if (nameKey === undefined) {
                nameKey = getKeyPart(key, 3) as 'farmingPower' | 'lastCheckInterest' | undefined | string;
            }

            if (result === null) {
                result = {} as IFarmingEntriesV2;
            }
            return this.addEntry(result, nameKey, value) || result;
        }, {} as IFarmingEntriesV2);
        const { farmingParams, toClaim } = this.calculateFarmingDetails(
            farmingEntriesByKeys as unknown as IFarmingEntriesV2,
            totalValues,
        );

        return {
            farmingParams,
            toClaim,
        };
    };

    fetchAnimalParamsOnAddress = async (address: addressId): Promise<Record<assetId, IFarmingEntriesV2>> => {
        const entries = await this.fetchAnimalFarmingEntriesOnAddress(address);

        const intermediateResult = entries.reduce((result: Record<assetId, any>, { key, value }) => {
            const [_address, _owner, _asset, assetId, paramName] = key.split('_');

            if (!(assetId in result)) {
                result[assetId] = {} as IFarmingEntriesV2;
            }

            this.addEntry(result[assetId], paramName as any, value);

            return result;
        }, {} as Record<assetId, any>);

        return Object.entries(intermediateResult)
            .filter(([, { farmingPower }]) => farmingPower > 0)
            .reduce((res, [animalId, entry]) => ({ ...res, [animalId]: entry }), {});
    };

    fetchFarmingGlobals = async (): Promise<IFarmingGlobalsV2> => {
        if (this.farmingGlobalsCache.data) {
            return this.farmingGlobalsCache.data;
        }
        const totalResponse = await this.fetchDataByKeys<FarmingGlobals>(['global_lastCheck_interest', 'total_staked']);

        const totalValues = {
            globalStaked: int(0),
            globalLastCheck: int(0),
        };
        totalResponse.forEach(({ key, value }) => {
            totalValues[`global${capitalize(getKeyPart(key, 1))}`] = value;
        });
        this.farmingGlobalsCache.data = totalValues;

        return this.farmingGlobalsCache.data;
    };

    fetchAnimalOwner = (animalId: animalId) => this.fetchDataByKey<Owner>(`${animalId}_owner`, { avoidCache: true });

    /**
     * Returns the duck farming details
     * @param {Object} currentBlockchainStateByKeys -
     * @param {string} address -
     * @param {string} nftId -
     * @param {Object} farmingGlobals -
     * @param {number} blockchainHeight -
     * @return { farmingParams: IFarmingParams; toClaim: number }
     */
    calculateFarmingDetails = (
        currentBlockchainStateByKeys: IFarmingEntriesV2,
        farmingGlobalsV2: IFarmingGlobalsV2,
    ): { farmingParams: IFarmingParamsV2; toClaim: number } => {
        const farmingParams: IFarmingParamsV2 = {
            lastCheckFarmedAmount: currentBlockchainStateByKeys.claimed,
            assetLastCheckInterest: currentBlockchainStateByKeys.lastCheckInterest,
            farmingPower: currentBlockchainStateByKeys.farmingPower,
            globalFarmingPower: parseInt(`${farmingGlobalsV2.globalStaked}`),
            globalLastCheck: parseInt(`${farmingGlobalsV2.globalLastCheck}`),
        };
        farmingParams.lastCheckFarmedAmount =
            farmingParams.lastCheckFarmedAmount === undefined || Number.isNaN(farmingParams.lastCheckFarmedAmount)
                ? int(0)
                : farmingParams.lastCheckFarmedAmount;
        return {
            farmingParams,
            toClaim: this.calculateToClaim({
                currentInterest: int(farmingParams.globalLastCheck),
                assetLastCheckInterest: farmingParams.assetLastCheckInterest,
                farmingPower: int(farmingParams.farmingPower),
            }),
        };
    };

    calculateToClaim = ({
        currentInterest,
        assetLastCheckInterest,
        farmingPower,
    }: {
        currentInterest: number;
        assetLastCheckInterest?: number;
        farmingPower?: number;
    }) => {
        const result = ((currentInterest - (assetLastCheckInterest ?? 0)) * (farmingPower ?? 0)) / 1e8;
        return result;
    };

    getEmptyFarmingItem = async (address: addressId): Promise<{ B?: number; G?: number; Y?: number; R?: number }> => {
        const emptyPerches = await this.fetchDataMatch(`address_${address}_${this.FARMING_ITEM_NAME}Available_.*?`, {
            avoidCache: true,
        });
        const result = {};
        const possibleColors = ['A', 'B', 'C', 'D'];
        emptyPerches.forEach((kv) => {
            const keyParts = kv.key.split('_');
            const color = keyParts[keyParts.length - 1].toUpperCase();
            if (possibleColors.indexOf(color) !== -1) {
                result[color] = kv.value;
            }
        });
        return result;
    };

    getState = async (): Promise<Array<{ key: string; value: any }>> => {
        const { data: state } = await this.helperService.fetchData(
            urlString(`addresses/data/${this.DAPP_ADDRESS}`),
            false,
        );

        return state;
    };

    getBlockchainBalances = async (): Promise<Array<{ key: string; value: any }>> => {
        const { data } = await this.helperService.fetchData(urlString(`assets/balance/${this.DAPP_ADDRESS}`), false);

        return data.balances;
    };

    getAnimalsFarmPower = async (data: { name: string; genotype: string; probability: number }[]) => {
        const possiblesAnimalFarmPower: { name: string; genotype: string; farmPower: number; probability: number }[] =
            [];
        for (const animal of data) {
            const farmPower = await this.helperService.getAnimalFarmPower(animal.name);
            possiblesAnimalFarmPower.push({
                name: animal.name,
                genotype: animal.genotype,
                farmPower: farmPower,
                probability: animal.probability,
            });
        }
        return possiblesAnimalFarmPower;
    };

    getAddressFarmPower = async (userAddress: string): Promise<number> => {
        const { data } = await this.helperService.fetchData(
            urlString(`addresses/data/${this.DAPP_ADDRESS}`, { matches: `total_staked_${userAddress}` }),
            false,
        );
        if (data != undefined) {
            if (data.length == 0) {
                return 0;
            } else {
                return data[0].value;
            }
        }
        return 0;
    };
}

export default CommonAnimalsFarmingService;
