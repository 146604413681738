/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DuckConnection, IDuckDetails, WithHuntParams } from '$shared/types';
import { urlString } from '$shared/utils';
import { isAddress } from '$shared/domain/guards';
import CommonDucksService from '$shared/services/ducks';
import { WithRentalParams } from '$shared/types/hunt';
import { IDuckDetailsLocked, IDuckDetailsV2, IPets } from '$shared/types/ducks';
import { getAddress } from '$shared/domain/constants';

import { API_URL, APP_URL, IMAGES_SERVICE_URL } from '$/constants';

import helperService from '../helper';
import auctionsService from '../auctions';
import breedingService from '../breeding';
import artefactService from '../artefact';
import assetsService from '../assets';
import productionService from '../production';
import { duckHouseService, megaDuckHouseService, xmasStbleService } from '../duck-houses';
import stakedDuckService from '../staked-duck';
import duckHuntService from '../duck-hunt';
import rentalDucksService from '../rental-ducks';
import warsService from '../wars';
import incubatorService from '../incubator';
import scanService from '../scan';
import veggFarmingService from '../vegg-farming';
import farmingService from '../farming';

class FrontendDucksService extends CommonDucksService {
    helperService = helperService;

    veggFarmingService = veggFarmingService;

    farmingService = farmingService;

    artefactService = artefactService;

    breedingService = breedingService;

    assetsService = assetsService;

    productionService = productionService;

    duckHouseService = duckHouseService;

    megaDuckHouseService = megaDuckHouseService;

    xmasStbleService = xmasStbleService;

    auctionService = auctionsService;

    stakedDuckService = stakedDuckService;

    duckHuntService = duckHuntService;

    rentalDucksService = rentalDucksService;

    warsService = warsService;

    incubatorService = incubatorService;

    scanService = scanService;

    APP_URL = APP_URL;

    API_URL = API_URL;

    IMAGES_SERVICE_URL = IMAGES_SERVICE_URL;

    DAPPS_CONTRACTS = {
        auction: getAddress('AUCTION_DAPP'),
        wars: getAddress('GAME_DAPP'),
        hunt: getAddress('HUNT_DAPP'),
        veggFarming: getAddress('VEGG_FARMING_DAPP'),
        farming: getAddress('DUCK_FARMING_DAPP'),
        rental: getAddress('RENT_DAPP_ADDRESS'),
        xmasStble: getAddress('XMAS_STBLE_DAPP'),
    };

    fetchOneDuckHuntDetails = async (nftId: string): Promise<WithHuntParams> => {
        const data = await this.duckHuntService.fetchStatsOnDuck(nftId);
        return {
            hunt: data.hunt,
            huntPower: data.huntPower,
        };
    };

    /** @deprecated */
    fetchOneDuckDetailsWithoutExtra = async (nftId: string): Promise<IDuckDetails> => {
        const [duck] = await Promise.all([this.getOneDuckDetails(nftId)]);

        return {
            ...duck,
        };
    };

    fetchOneDuckDetailsWithoutExtraV2 = async (nftId: string): Promise<IDuckDetailsV2> => {
        const [duck] = await Promise.all([this.getOneDuckDetailsV2(nftId)]);

        return {
            ...duck,
        };
    };

    //** @deprecated */
    fetchOneDuckDetails = async (nftId: string): Promise<IDuckDetails & WithHuntParams & WithRentalParams> => {
        const [duck, data, rental] = await Promise.all([
            this.getOneDuckDetails(nftId),
            this.duckHuntService.fetchStatsOnDuck(nftId),
            this.rentalDucksService.fetchOneRentalDuck(nftId),
        ]);

        return {
            ...duck,
            hunt: data.hunt,
            huntPower: data.huntPower,
            rental,
        };
    };

    fetchOneDuckDetailsV2 = async (nftId: string): Promise<IDuckDetailsLocked & WithHuntParams & WithRentalParams> => {
        const [duck, data, rental] = await Promise.all([
            this.getOneDuckDetailsV2(nftId, true),
            this.duckHuntService.fetchStatsOnDuck(nftId),
            this.rentalDucksService.fetchOneRentalDuck(nftId),
        ]);

        return {
            ...duck,
            hunt: data.hunt,
            huntPower: data.huntPower,
            rental,
        };
    };

    fetchFreeDucksBreeding = async (address: addressId): Promise<IDuckDetailsV2[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<any>(urlString(`${API_URL}/v2/addresses/${address}/ducks`), {
            params: {
                size: 200_000,
                details: false,
            },
        });
        const { data: result } = data;
        return result.data.filter((duck: IDuckDetailsV2) => duck.canBreed && !this.isLastGeneration(duck.name));
    };

    fetchFreeDucks = async (address: addressId, details = false): Promise<IDuckDetailsV2[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<any>(urlString(`${API_URL}/v2/addresses/${address}/ducks`), {
            params: {
                size: 200_000,
                details,
            },
        });
        const { data: result } = data;
        return result.data;
    };

    fetchUserDuckOnFarming = async (address: addressId): Promise<IDuckDetailsV2[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<any>(
            urlString(`${API_URL}/v2/addresses/${address}/ducks/locked`),
            {
                params: {
                    size: 200_000,
                    withStakedOn: ['farming'],
                },
            },
        );
        const { data: result } = data;
        return result.data;
    };

    fetchUserPageDucksV2 = async (address: addressId): Promise<any> => {
        const allDucksFromUser: Array<{ [key: string]: any }> = [];
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<any>(
            urlString(`${API_URL}/v2/addresses/${address}/ducks/locked`),
            {
                params: { size: 200_000 },
            },
        );
        const { data: result } = data;

        const AddAchievementsPromises = result.data.map((duck) => duck);
        const resultDuckWithAch = await scanService.fetchAchievementsForDucks(AddAchievementsPromises);
        // TODO: check if rental is correct
        AddAchievementsPromises.forEach((duck) => {
            const duckDetails = {
                ...duck,
                achievements: resultDuckWithAch[duck.assetId].a,

                onFarming: duck.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.farming,
                ),
                onFarmingVegg: duck.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.veggFarming,
                ),
                onSale: duck.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.auction,
                ),
                // TODO: check if rental is correct
                inRental: duck.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.rental,
                ),
                inWars: duck.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.wars,
                ),
                // TODO: hunt does not appear to be correct in locks?
                inHunt: duck.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.hunt,
                ),
            };

            allDucksFromUser.push(duckDetails);
        });

        return allDucksFromUser;
    };

    fetchUserPageDucksHuntParams = async (ducks: any): Promise<IDuckDetails[]> => this.getDucksHuntParams(ducks);

    fetchDuckHuntLockedDucks = async (address: addressId): Promise<IDuckDetails[]> => {
        if (!isAddress(address)) {
            return [];
        }
        const { data } = await this.helperService.http.get<IDuckDetails[]>(
            urlString(`${API_URL}/v1/address/${address}/ducks`, {
                withStakedOn: ['hunt', 'onXmasStable'],
                with: ['achievements', 'breedStatus'],
            }),
        );
        return data;
    };

    fetchPetsBonusses = async (address: addressId): Promise<any> => {
        const { data } = await this.helperService.http.get<{ data: IPets }>(`${API_URL}/hunt/v1/pets/${address}`);
        return data?.data;
    };

    fetchDuckConnections = async (assetId: duckId): Promise<DuckConnection[]> => {
        const { data: { data = [] } = {} } = await this.helperService.http.get<{ data: DuckConnection[] }>(
            `${API_URL}/v2/ducks/${assetId}/connections`,
        );

        return data;
    };
}

export default FrontendDucksService;
