import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { TURTLE_COLORS } from '$shared/constants';
import { isBirthdayEvent } from '$shared/domain/events';
import { ITurtleDetailsLocked, ITurtleDetailsV2 } from '$shared/types/turtles';

import { useTurtleDetailsV2, useTurtleParams } from '$/hooks/turtle';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import getUrl from '$/utils/getUrl';
import ROUTES from '$/constants/routes';
import { getFPColor } from '$/components/old-pages/Portfolio/misc';
import { ETurtleTabs, TTurtleCardFooter } from '$/types/turtle';
import Title from '$/components/common/Title';
import TurtleTags from '$/components/turtle/TurtleTags';
import { Props as ButtonProps } from '$/components/common/Button';
import Achievements from '$/components/duck/Achievements';

import { Card, CardTemplate } from '../Card';
import { getButtonDisabled, getButtonText, getOnClick } from './config';
import st from './Turtle.module.scss';
import TurtleCoverContent from './TurtleCoverContent';

export interface IProps {
    isAuth?: boolean;
    disabled?: boolean;
    withoutGeneration?: boolean;
    withoutLink?: boolean;
    withoutAuth?: boolean;
    buttonText?: ReactNode;
    buttonSize?: ButtonProps['size'];
    overlay?: ReactNode;
    params: ITurtleDetailsLocked;
    onClick?: (params: ITurtleDetailsV2) => Promise<void>;
    className?: string;
    to?: string;
    secondSection?: ReactNode;
    secondSectionClassName?: string;
    imageClassName?: string;
    footer?: TTurtleCardFooter;
    footerEl?: ReactNode;
    canBreed?: boolean;
}

const Turtle: FC<IProps> = (props) => {
    const {
        isAuth,
        disabled,
        withoutGeneration,
        withoutLink,
        withoutAuth,
        buttonSize,
        buttonText,
        children,
        className,
        footer,
        footerEl,
        imageClassName,
        overlay,
        params,
        params: { assetId, canBreed, name, onFarming, onSale, farmingParams },
        secondSection,
        secondSectionClassName,
        to,
        onClick,
    } = props;

    const [cacheTs, setCacheTs] = useState(0);
    const { t } = useTranslation();

    const {
        bgColor,
        color = '',
        animalDetails,
        generationName,
        generationNumber,
    } = useTurtleParams(params, { cacheTs }) || {};

    const href = to || getUrl(ROUTES.TURTLE, { nftId: assetId, tab: ETurtleTabs.MAIN });
    const { name: title, image } = animalDetails || {};
    const { data: turtle } = useTurtleDetailsV2(assetId);
    const achievementsData = turtle?.achievements;
    const tagsParams = {
        assetId,
        bgColor,
        animalNumber: turtle?.issueNumber,
        generationName,
        generationNumber,
        name,
        farmingPower: turtle?.basePower,
        farmingColor: getFPColor(turtle?.basePower),
        withoutGeneration,
        canBreed,
    };
    const claim = 0;
    const clickHandler = useEffectiveCallback(async () => {
        await onClick?.(params);
        setCacheTs(Date.now());
    });
    const Component = withoutAuth ? CardTemplate : Card;
    const bg = isBirthdayEvent()
        ? `center / cover url("/turtles/birthday-bg/${color}.svg")`
        : `#${TURTLE_COLORS[color]}`;

    const defaultFooter = {
        buttonSize,
        disabled: getButtonDisabled({ disabled, withoutLink, toClaim: farmingParams?.toClaim, onFarming, onClick }),
        text: getButtonText({
            onSale,
            onFarming,
            claim,
            buttonText,
            t,
        }),
    };

    return (
        <Component
            className={className}
            secondSectionClassName={secondSectionClassName}
            isAuth={isAuth}
            background={bg}
            coverClassName={cn(imageClassName)}
            cover={
                <>
                    <TurtleCoverContent image={image} title={title} />
                    {overlay}
                </>
            }
            footerEl={footerEl}
            footer={footer || defaultFooter}
            {...{
                secondSection,
            }}
            {...(!withoutLink && { to: href })}
            {...(onClick && { onClick: getOnClick({ onFarming, claim, onClick: clickHandler }) })}
        >
            <Title as="h3" size="h2" className={st.title}>
                {title}
            </Title>
            <TurtleTags data={tagsParams} className={st.tags} />
            <Achievements className={st.achievements} items={achievementsData} itemClassName={st.achievement} />
            {children}
        </Component>
    );
};

export default Turtle;
