import type { RecordByEnv } from '../types';

export type Addresses = {
    ORACLE: string;
    DUCK_INCUBATOR_DAPP: string;
    TURTLES_INCUBATOR_DAPP: string;
    AUCTION_DAPP: string;
    COUPONS_DAPP: string;
    DUCK_BREEDER_DAPP: string;
    TURTLES_BREEDER_DAPP: string;
    DUCK_FARMING_DAPP: string;
    LEGACY_FARMING_DAPP: string;
    VEGG_FARMING_DAPP: string;
    TURTLES_FARMING_DAPP: string;
    REFERRAL_DAPP: string;
    DUCK_REBIRTH_DAPP: string;
    TURTLE_REBIRTH_DAPP: string;
    CANINE_REBIRTH_DAPP: string;
    FELINE_REBIRTH_DAPP: string;
    BABY_DUCKS_DAPP: string;
    BREEDING_BABY_DUCKS_DAPP: string;
    GAME_DAPP: string;
    HUNT_DAPP: string;
    LOOT_BOXES_DAPP: string;
    ACCOUNT_BOOSTER_DAPP: string;
    MEGA_DUCK_HOUSE_DAPP: string;
    DUCK_HOUSE_DAPP: string;
    LEGACY_MEGA_DUCK_HOUSE_DAPP: string;
    LEGACY_DUCK_HOUSE_DAPP: string;
    XMAS_STBLE_DAPP: string;
    WEARABLES_DAPP: string;
    COLLECTIVE_FARMS_MASTER: string;
    MARKETPLACE_PROXY: string;
    SWAP_DAPP: string;
    SWOP_FI_EGG_WAVES_DAPP: string;
    SWOP_DAPP: string;
    SPENCER_PROXY_DAPP: string;
    PUZZLE_POOLS: string[];
    REBIRTH_PROMO: string;
    COMMUNITY_DUCK_DAPP: string;
    COMMUNITY_DUCK_DAPP_2023: string;
    SWOP_PROMO: string;
    SDUCK_DAPP: string;
    OPENSEA_GATEWAY_DAPP: string;
    CHARITY: string;
    ROPE_EGG_DAPP: string;
    DUCK_CAPACITY_DAPP: string;
    SKILL_TREE_DAPP: string;
    HUNT_TRANSFER_ADDRESS: string;
    BURN_DAPP: string;
    LOTTERY_DAPP_ADDRESS: string;
    RENT_DAPP_ADDRESS: string;
    AMM_DAPP_LAYER_2_ADDRESS: string;
    PETE_DAPP_ADDRESS: string;
    CF_AMM_DAPP: string;
    MUTANT_STAKING_DAPP_ADDRESS: string;
    LEASING_ADDRESS: string;
    CANINES_BREEDER_DAPP: string;
    FELINES_BREEDER_DAPP: string;
    CANINES_INCUBATOR_DAPP: string;
    FELINES_INCUBATOR_DAPP: string;
    CANINES_FARMING_DAPP: string;
    FELINES_FARMING_DAPP: string;
};

const ADDRESS: RecordByEnv<Addresses> = {
    DEVELOPMENT: {
        ORACLE: '3P4vXL8gU27PhQv8sMT9jmoQY3oFqJNRWT9',
        DUCK_INCUBATOR_DAPP: '3P6TwNU39Ykkbeqhn5TE4a2736xsA1vXemM',
        TURTLES_INCUBATOR_DAPP: '3PGvfeMaCezvCWyz4RGeoQpmwzrWwv7NLYU',
        CANINES_BREEDER_DAPP: '3PFWCFZLxTbCiPFwuXWFJsCyv4pcPP8dP6t',
        FELINES_BREEDER_DAPP: '3PMisRTMyePn3R2HAJbXT58QpGoEhj25v7J',
        CANINES_INCUBATOR_DAPP: '3P4YUDVTsDtqBkdtonR2T79yddwjSTuRg71',
        FELINES_INCUBATOR_DAPP: '3P2dqen45M2z9xG6QXqhuk43r2PMFiExGxp',
        CANINES_FARMING_DAPP: '3PFjynLTApECwzmbdQ45eRRMSmkMw6n4V7s',
        FELINES_FARMING_DAPP: '3PQFjXwRYZMmeUUjV4yTmzQWgFrW6cuBM2H',
        AUCTION_DAPP: '3P8gsxa1xt1xSjDP4HR728y7o9QQginK2eU',
        COUPONS_DAPP: '3P7aVqHv6fXkYuS4sRW2K17ENZMsbXi3sPV',
        DUCK_BREEDER_DAPP: '3P9REuLBnYBafDqtEu6hfzs2Rv2haSU158y',
        TURTLES_BREEDER_DAPP: '3PAAYogFMWzD3enj9WaBhmrfj7pEuAqRXVG',
        DUCK_FARMING_DAPP: '3P7i87v9nfk6Tft8YumLrJJPLRjRrALk4iL',
        LEGACY_FARMING_DAPP: '3PH75p2rmMKCV2nyW4TsAdFgFtmc61mJaqA',
        VEGG_FARMING_DAPP: '3P4Hyw1LiE1UKxFADBkBsMrVRdQcKaByRio',
        TURTLES_FARMING_DAPP: '3PGXMdo3TNER2mstPL5pSz65C5nKi7sjkZU',
        REFERRAL_DAPP: '3PGZyyPg7Mx91yaNT8k3MWxSQzuzusMUyzX',
        DUCK_REBIRTH_DAPP: '3PKL9tfUmNciXvbSA3zpXr6PnDNnhn936ba',
        TURTLE_REBIRTH_DAPP: '3PNaRW9FuMUWpAzZkjrdvHBpZxeX7kNrDoY',
        CANINE_REBIRTH_DAPP: '3PAvZSshLfaefR3atDSDu6siNfLMbi3dpJJ',
        FELINE_REBIRTH_DAPP: '3PLLdSgNcBL6bijqmXGa9PdL4tdbFneXT8P',
        BABY_DUCKS_DAPP: '3PKwLVb8jYk58oYbfXxs2dUjD3YEKeqGWrX',
        BREEDING_BABY_DUCKS_DAPP: '3P36HH7i6BBuuNp7FHQd6WX8gzfogi778i6',
        GAME_DAPP: '3PCoF5ZnsQJKAJJCoSqUcVVqJ2Dm4fvn9ar',
        HUNT_DAPP: '3P96rN9M2g7E8WtgLC6XQgoJ2rz341tpkbG',
        LOOT_BOXES_DAPP: '3PAi1ePLQrYrY3jj9omBtT6isMkZsapbmks',
        WEARABLES_DAPP: '3PHUMZkr66B38WxHV1boULaEjjF7giGgVy7',
        ACCOUNT_BOOSTER_DAPP: '3PG7U7XXBt4yLq3toAtcxmXjYZNergS29uQ',
        MUTANT_STAKING_DAPP_ADDRESS: '3PH1jsDiwuCB18go9PN6hpgiLRGQCf1A22h',
        MEGA_DUCK_HOUSE_DAPP: '3P7s4tnbS4tahPn6tWHySS8qeNjZhAaWhL9',
        DUCK_HOUSE_DAPP: '3PFTdVX3PmuKiBF75jAG8DUtDRM13uYDLS5',
        LEGACY_MEGA_DUCK_HOUSE_DAPP: '3PKX8mjwxxJGmC6uUV7opAwMmFUN61JnmG9',
        LEGACY_DUCK_HOUSE_DAPP: '3P7GafyRyfQu58o6iQk5fHbkgVkQwVNPJoQ',
        XMAS_STBLE_DAPP: '3PHK4X9r8U6GYQBADf6iXaz9VnL2yFU2ERr',
        COLLECTIVE_FARMS_MASTER: '3P9gDaTekEqCfV76CUXd6WCeoMqa8eDD1hy',
        MARKETPLACE_PROXY: '3PQ5smtcz4F9F3rhbj3PX6GBgM4YtPkeHCg',
        SWAP_DAPP: '3PNkQAzsSfguiMh2EHeithEbNzyAvZSNabf',
        SWOP_FI_EGG_WAVES_DAPP: '',
        SWOP_DAPP: '3PCUJBpMXtqYKxMiDtKcTeEQcKukfMeoctR',
        SPENCER_PROXY_DAPP: '3PBroEophsemuK1NEEBYAGVM8Q8JaKUCytH',
        PUZZLE_POOLS: ['3PPRHHF9JKvDLkAc3aHD3Kd5tRZp1CoqAJa', '3PKYPKJPHZENAAwH9e7TF5edDgukNxxBt3M'],
        REBIRTH_PROMO: '',
        COMMUNITY_DUCK_DAPP: '3PCUtaK8RJ39V85f9x5R3Fj1JDnhdjZvBuM',
        COMMUNITY_DUCK_DAPP_2023: '3PBF6bFnPMUt844mPB2LS9xuN98m3LZ381T',
        SWOP_PROMO: '3P8zG7GNt4JphWXFTG39c2nmNmRRmqs4SEz',
        SDUCK_DAPP: '3P2SMcPXPsCQ21XuDRhu3YZniPFcYTP6iFH',
        OPENSEA_GATEWAY_DAPP: '3PMbHWvG3Sbcxm6bhpXm7nK9f1MoYBJD6EH',
        CHARITY: '3PHWDbbJz4tRr9QUNKMotjCYP9CGBF8Lx4Q',
        ROPE_EGG_DAPP: '3PNuQXQQjEERkDSbHKnXjeawcc6MWws8e79',
        DUCK_CAPACITY_DAPP: '3P8prjYwq9dxRqPiyYAjQpAnuei6eTAerSV',
        SKILL_TREE_DAPP: '3P5x9RxuqbMaVFPsaycvXHeFZXPmcswxA1c',
        HUNT_TRANSFER_ADDRESS: '3P44yqcpfhPF2iC1nptm2ZJCTaN7VYhz9og',
        BURN_DAPP: '3P57np8oou9yBbg85Nk4FDYTC46exnTsQk1',
        LOTTERY_DAPP_ADDRESS: '3PAoGogbTXt7nC4mvK7C8fQPJ1sQ5Yww21a',
        RENT_DAPP_ADDRESS: '3P8FgFUEvZCD1c6FkZM3zT5eYezocydyxDD',
        AMM_DAPP_LAYER_2_ADDRESS: '3PApVHkhfXbtavRDKEmUnQ6erZ3FqvTmvTs',
        PETE_DAPP_ADDRESS: '3PBf2jd3ZEPX7NHQvVarscTjLQ8bk43uAfn',
        CF_AMM_DAPP: '3PPWSpENBfmvH7Hkz7vqMrwywTZYEtLEBaH',
        LEASING_ADDRESS: '3PA1KvFfq9VuJjg45p2ytGgaNjrgnLSgf4r',
    },
    PRODUCTION: {
        ORACLE: '3PJgZ6AK1WVCpdCmEZpesHmaKvrQDdXG5og',
        DUCK_INCUBATOR_DAPP: '3PEktVux2RhchSN63DsDo4b4mz4QqzKSeDv',
        CANINES_BREEDER_DAPP: '3PQdwhdjWHZ1hacy2dLPnRafi86cTvuJPNX',
        FELINES_BREEDER_DAPP: '3PKhghDw2necJLA6mGuQuC4juWzmu7Subu2',
        TURTLES_INCUBATOR_DAPP: '3PE9yT1qVM9hsRkpiV6Y7AKEubafyT5NCuB',
        CANINES_INCUBATOR_DAPP: '3PD6L4yr52Lac9qeqXNoi4mAiiak5ZBnx5D',
        FELINES_INCUBATOR_DAPP: '3P9SJwLFhgRQG5jAQinzZFyPzE9cP3oyu8Q',
        CANINES_FARMING_DAPP: '3PCBQJP8XEK5sgziA9pu3AHKnegmBxxojaY',
        FELINES_FARMING_DAPP: '3P6KR6QtXmPH8onFKMe276bVKUNVRzmNGSR',
        AUCTION_DAPP: '3PEBtiSVLrqyYxGd76vXKu8FFWWsD1c5uYG',
        COUPONS_DAPP: '3P5W1D4UuSZrNfeZFJZcD5wT2avfbHbzTvS',
        DUCK_BREEDER_DAPP: '3PDVuU45H7Eh5dmtNbnRNRStGwULA7NY6Hb',
        TURTLES_BREEDER_DAPP: '3P97G4z2qH2pZGPYwWw47pFQu7cpyjR579t',
        DUCK_FARMING_DAPP: '3PEpQ4LaeZA6DVMyrgU3PTFBprAF4tRziBD',
        LEGACY_FARMING_DAPP: '3PAETTtuW7aSiyKtn9GuML3RgtV1xdq1mQW',
        VEGG_FARMING_DAPP: '3P7s9drVRL4Kw3edKv5RhNd5Qrwxu1cU3d3',
        TURTLES_FARMING_DAPP: '3PG1QrkrsZaEpo8Bhe34geNNA1d9M8REGrv',
        REFERRAL_DAPP: '3P8ejTkfRpz9WqCwCuihesNXU5k3zmFFfVe',
        DUCK_REBIRTH_DAPP: '3PCC6fVHNa6289DTDmcUo3RuLaFmteZZsmQ',
        TURTLE_REBIRTH_DAPP: '3PM7TWS85ZupzH1k5wYQa4HPJjEn95j5N7P',
        CANINE_REBIRTH_DAPP: '3P4Anrwd7rKDGyxXZRaocXVbKtg8TKEzf6D',
        FELINE_REBIRTH_DAPP: '3PMUJt5Voyxy12DjsKVKtFctA4HpAEfSgc5',
        BABY_DUCKS_DAPP: '3PKmLiGEfqLWMC1H9xhzqvAZKUXfFm8uoeg',
        BREEDING_BABY_DUCKS_DAPP: '3PKdgEHMR7HzTQdXkHqTPRUWtBXhYF1NihS',
        GAME_DAPP: '3PR87TwfWio6HVUScSaHGMnFYkGyaVdFeqT',
        HUNT_DAPP: '3P3Gtbq2raMqBkks8tr6sTZH9uHCoVTBfQ8',
        LOOT_BOXES_DAPP: '3P5E9xamcWoymiqLx8ZdmR7o4fJSRMGp1WR',
        WEARABLES_DAPP: '3PCxXZk5r9nL8ppcqRjDAqeYWzRQSDapxWw',
        ACCOUNT_BOOSTER_DAPP: '3PNbBGH1omxP5yMewxvDcuLrb4RAKgKt1uD',
        MUTANT_STAKING_DAPP_ADDRESS: '3PH1jsDiwuCB18go9PN6hpgiLRGQCf1A22h',
        MEGA_DUCK_HOUSE_DAPP: '3PDEbskwA7AXaWN9Nyh1PQxJyNxDjXFPubY',
        DUCK_HOUSE_DAPP: '3PBPQYB9EMuMFNy6jZCk1jvembMPkKBv1v1',
        LEGACY_MEGA_DUCK_HOUSE_DAPP: '3PFfetFUtrnxjv8Q66t9U5fqLFbyoFxzCQo',
        LEGACY_DUCK_HOUSE_DAPP: '3PDbviVyp8vGmCnnjf2rHT4fpUMYe8XtgSL',
        XMAS_STBLE_DAPP: '3PPyYYmEkvneaAoBPLCe3BEmtzTiKdco91x',
        COLLECTIVE_FARMS_MASTER: '3PDktsxDVEcoobpEBJcausnKo4enhcWUDEF',
        MARKETPLACE_PROXY: '3PL1tnAkQUP3jQNGUu2NvfAuWuz5kqrhctt',
        SWAP_DAPP: '3PJQUUiJdvz9etUKED9ju7o7VrcNMtnkXBU',
        SWOP_FI_EGG_WAVES_DAPP: '3PNVFWopwCD9CgGXkpYWEY94oQ5XCAEXBmQ',
        SWOP_DAPP: '3PCUJBpMXtqYKxMiDtKcTeEQcKukfMeoctR',
        SPENCER_PROXY_DAPP: '3PBroEophsemuK1NEEBYAGVM8Q8JaKUCytH',
        PUZZLE_POOLS: ['3PPRHHF9JKvDLkAc3aHD3Kd5tRZp1CoqAJa', '3PKYPKJPHZENAAwH9e7TF5edDgukNxxBt3M'],
        REBIRTH_PROMO: '3PQVodJ91e32iii4TkKPeuHmPFBNbjtNmfn',
        COMMUNITY_DUCK_DAPP: '3PPRqZYVaBUgTN4zGUFyYEcKLbqjVuoDZFP',
        COMMUNITY_DUCK_DAPP_2023: '3PBF6bFnPMUt844mPB2LS9xuN98m3LZ381T',
        SWOP_PROMO: '3P6s9C39Ak5FAQ3F5VapYJ3ULxgbsAReXWD',
        SDUCK_DAPP: '3P3pDosq4GCwfJkvq4yqKvvoTwmoqc9qPmo',
        OPENSEA_GATEWAY_DAPP: '3PPfDHC6hNqDMxRArJvJRkYfC8y6S3rSnYb',
        CHARITY: '3PHWDbbJz4tRr9QUNKMotjCYP9CGBF8Lx4Q',
        ROPE_EGG_DAPP: '3P6dr3R6kKCsiRd1ebPx8XM4r1qdmUyHAN8',
        DUCK_CAPACITY_DAPP: '3P472BzRvamyTwGWD2os6oQNzvy8FYt7uSG',
        SKILL_TREE_DAPP: '3P5gckRPNh1ve5mZS4SkFY3T8pYqaApjqCi',
        HUNT_TRANSFER_ADDRESS: '3P7eZotfwNFYWGm9YeWhTZed1JdLdiszuh7',
        BURN_DAPP: '3PFq5vswexHUS15BXYaKMXtTDVhkUfPXoG2',
        LOTTERY_DAPP_ADDRESS: '3PCVPZDhdLoGNvxNivQ2Cn1Rq6rJn1RKy6P',
        RENT_DAPP_ADDRESS: '3P355gZeQTTSwE5Cynz9FQekvWJRhASBHFC',
        AMM_DAPP_LAYER_2_ADDRESS: '3PJwsxCtCXTxex5dPSFjYGp95mxF1BDJDnq',
        PETE_DAPP_ADDRESS: '3PBf2jd3ZEPX7NHQvVarscTjLQ8bk43uAfn',
        CF_AMM_DAPP: '3P2A54fWwUktfGVDpNdGaDPdgGbfA3LR1aV',
        LEASING_ADDRESS: '3PA1KvFfq9VuJjg45p2ytGgaNjrgnLSgf4r',
    },
    TEST: {
        ORACLE: '3P4vXL8gU27PhQv8sMT9jmoQY3oFqJNRWT9',
        DUCK_INCUBATOR_DAPP: '3P6TwNU39Ykkbeqhn5TE4a2736xsA1vXemM',
        CANINES_BREEDER_DAPP: '3PFWCFZLxTbCiPFwuXWFJsCyv4pcPP8dP6t',
        FELINES_BREEDER_DAPP: '3PMisRTMyePn3R2HAJbXT58QpGoEhj25v7J',
        TURTLES_INCUBATOR_DAPP: '3PGvfeMaCezvCWyz4RGeoQpmwzrWwv7NLYU',
        CANINES_INCUBATOR_DAPP: '3P4YUDVTsDtqBkdtonR2T79yddwjSTuRg71',
        FELINES_INCUBATOR_DAPP: '3P2dqen45M2z9xG6QXqhuk43r2PMFiExGxp',
        CANINES_FARMING_DAPP: '3PFjynLTApECwzmbdQ45eRRMSmkMw6n4V7s',
        FELINES_FARMING_DAPP: '3PQFjXwRYZMmeUUjV4yTmzQWgFrW6cuBM2H',
        AUCTION_DAPP: '3P8gsxa1xt1xSjDP4HR728y7o9QQginK2eU',
        COUPONS_DAPP: '3P7aVqHv6fXkYuS4sRW2K17ENZMsbXi3sPV',
        DUCK_BREEDER_DAPP: '3P9REuLBnYBafDqtEu6hfzs2Rv2haSU158y',
        TURTLES_BREEDER_DAPP: '3PAAYogFMWzD3enj9WaBhmrfj7pEuAqRXVG',
        DUCK_FARMING_DAPP: '3P7i87v9nfk6Tft8YumLrJJPLRjRrALk4iL',
        LEGACY_FARMING_DAPP: '3PH75p2rmMKCV2nyW4TsAdFgFtmc61mJaqA',
        VEGG_FARMING_DAPP: '3P4Hyw1LiE1UKxFADBkBsMrVRdQcKaByRio',
        TURTLES_FARMING_DAPP: '3PGXMdo3TNER2mstPL5pSz65C5nKi7sjkZU',
        REFERRAL_DAPP: '3PGZyyPg7Mx91yaNT8k3MWxSQzuzusMUyzX',
        DUCK_REBIRTH_DAPP: '3PKL9tfUmNciXvbSA3zpXr6PnDNnhn936ba',
        TURTLE_REBIRTH_DAPP: '3PNaRW9FuMUWpAzZkjrdvHBpZxeX7kNrDoY',
        CANINE_REBIRTH_DAPP: '3PAvZSshLfaefR3atDSDu6siNfLMbi3dpJJ',
        FELINE_REBIRTH_DAPP: '3PLLdSgNcBL6bijqmXGa9PdL4tdbFneXT8P',
        BABY_DUCKS_DAPP: '3PKwLVb8jYk58oYbfXxs2dUjD3YEKeqGWrX',
        BREEDING_BABY_DUCKS_DAPP: '3P36HH7i6BBuuNp7FHQd6WX8gzfogi778i6',
        GAME_DAPP: '3PCoF5ZnsQJKAJJCoSqUcVVqJ2Dm4fvn9ar',
        HUNT_DAPP: '3P96rN9M2g7E8WtgLC6XQgoJ2rz341tpkbG',
        LOOT_BOXES_DAPP: '3PAi1ePLQrYrY3jj9omBtT6isMkZsapbmks',
        WEARABLES_DAPP: '3PHUMZkr66B38WxHV1boULaEjjF7giGgVy7',
        MUTANT_STAKING_DAPP_ADDRESS: '3PH1jsDiwuCB18go9PN6hpgiLRGQCf1A22h',
        ACCOUNT_BOOSTER_DAPP: '3PG7U7XXBt4yLq3toAtcxmXjYZNergS29uQ',
        MEGA_DUCK_HOUSE_DAPP: '3P7s4tnbS4tahPn6tWHySS8qeNjZhAaWhL9',
        DUCK_HOUSE_DAPP: '3PFTdVX3PmuKiBF75jAG8DUtDRM13uYDLS5',
        LEGACY_MEGA_DUCK_HOUSE_DAPP: '3PKX8mjwxxJGmC6uUV7opAwMmFUN61JnmG9',
        LEGACY_DUCK_HOUSE_DAPP: '3P7GafyRyfQu58o6iQk5fHbkgVkQwVNPJoQ',
        XMAS_STBLE_DAPP: '3PHK4X9r8U6GYQBADf6iXaz9VnL2yFU2ERr',
        COLLECTIVE_FARMS_MASTER: '3P9gDaTekEqCfV76CUXd6WCeoMqa8eDD1hy',
        MARKETPLACE_PROXY: '3PQ5smtcz4F9F3rhbj3PX6GBgM4YtPkeHCg',
        SWAP_DAPP: '3PNkQAzsSfguiMh2EHeithEbNzyAvZSNabf',
        SWOP_FI_EGG_WAVES_DAPP: '',
        SWOP_DAPP: '3PCUJBpMXtqYKxMiDtKcTeEQcKukfMeoctR',
        SPENCER_PROXY_DAPP: '3PBroEophsemuK1NEEBYAGVM8Q8JaKUCytH',
        PUZZLE_POOLS: ['3PPRHHF9JKvDLkAc3aHD3Kd5tRZp1CoqAJa', '3PKYPKJPHZENAAwH9e7TF5edDgukNxxBt3M'],
        REBIRTH_PROMO: '',
        COMMUNITY_DUCK_DAPP: '3PCUtaK8RJ39V85f9x5R3Fj1JDnhdjZvBuM',
        COMMUNITY_DUCK_DAPP_2023: '3PBF6bFnPMUt844mPB2LS9xuN98m3LZ381T',
        SWOP_PROMO: '3P8zG7GNt4JphWXFTG39c2nmNmRRmqs4SEz',
        SDUCK_DAPP: '3P2SMcPXPsCQ21XuDRhu3YZniPFcYTP6iFH',
        OPENSEA_GATEWAY_DAPP: '3PMbHWvG3Sbcxm6bhpXm7nK9f1MoYBJD6EH',
        CHARITY: '3PHWDbbJz4tRr9QUNKMotjCYP9CGBF8Lx4Q',
        ROPE_EGG_DAPP: '3PNuQXQQjEERkDSbHKnXjeawcc6MWws8e79',
        DUCK_CAPACITY_DAPP: '3P8prjYwq9dxRqPiyYAjQpAnuei6eTAerSV',
        SKILL_TREE_DAPP: '3P5x9RxuqbMaVFPsaycvXHeFZXPmcswxA1c',
        HUNT_TRANSFER_ADDRESS: '3P44yqcpfhPF2iC1nptm2ZJCTaN7VYhz9og',
        BURN_DAPP: '3P57np8oou9yBbg85Nk4FDYTC46exnTsQk1',
        LOTTERY_DAPP_ADDRESS: '3PAoGogbTXt7nC4mvK7C8fQPJ1sQ5Yww21a',
        RENT_DAPP_ADDRESS: '3P8FgFUEvZCD1c6FkZM3zT5eYezocydyxDD',
        AMM_DAPP_LAYER_2_ADDRESS: '3PApVHkhfXbtavRDKEmUnQ6erZ3FqvTmvTs',
        PETE_DAPP_ADDRESS: '3PBf2jd3ZEPX7NHQvVarscTjLQ8bk43uAfn',
        CF_AMM_DAPP: '3PPWSpENBfmvH7Hkz7vqMrwywTZYEtLEBaH',
        LEASING_ADDRESS: '3PA1KvFfq9VuJjg45p2ytGgaNjrgnLSgf4r',
    },
};

export default ADDRESS;
