/* eslint-disable @typescript-eslint/no-explicit-any */
import CommonFelinesService from '$shared/services/felines';
import { isAddress } from '$shared/domain/guards';
import { IAnimalDetails } from '$shared/types/animals';
import { urlString } from '$shared/utils';
import { getAddress } from '$shared/domain/constants';

import { API_URL, APP_URL, IMAGES_SERVICE_URL } from '$/constants';

import assetsService from '../assets';
import auctionService from '../auctions';
import felineBreedingService from '../feline-breeding';
import felinesFarmingService from '../felines-farming/instance';
import helperService from '../helper';
import productionService from '../production';
import scanService from '../scan';
import felinesIncubatorService from '../felines-incubator';

class FrontendFelinesService extends CommonFelinesService {
    breedingService = felineBreedingService;

    farmingService = felinesFarmingService;

    incubatorService = felinesIncubatorService;

    helperService = helperService;

    assetsService = assetsService;

    productionService = productionService;

    auctionService = auctionService;

    scanService = scanService;

    IMAGES_SERVICE_URL = IMAGES_SERVICE_URL;

    APP_URL = APP_URL;

    API_URL = API_URL;

    DAPPS_CONTRACTS = {
        farming: getAddress('FELINES_FARMING_DAPP'),
        auction: getAddress('AUCTION_DAPP'),
        rental: getAddress('RENT_DAPP_ADDRESS'),
    };

    fetchUserPageAnimal = async (address: addressId): Promise<IAnimalDetails[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<IAnimalDetails[]>(
            urlString(`${API_URL}/v1/address/${address}/felines`, {
                withStakedOn: ['none', 'farming', 'auction'],
                with: ['breedStatus', 'farmingStats', 'achievements'],
            }),
        );

        return data;
    };

    fetchOneAnimalDetails = async (nftId: string): Promise<IAnimalDetails> => {
        const [feline] = await Promise.all([this.getOneAnimalDetails(nftId)]);
        return feline;
    };

    fetchOneAnimalDetailsV2 = async (nftId: string): Promise<any> => {
        const [feline] = await Promise.all([this.getOneAnimalDetailsV2(nftId, true)]);
        return feline;
    };

    fetchUserPageFelinesV2 = async (address: addressId): Promise<any> => {
        const allFelineFromUser: Array<{ [key: string]: any }> = [];
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get(
            urlString(`${API_URL}/v2/addresses/${address}/felines/locked`),
            {
                params: { size: 200_000 },
            },
        );

        const { data: result } = data;

        result.data.forEach((feline: IAnimalDetails) => {
            const felineDetails = {
                ...feline,
                issueNumber: feline.issueNumber,

                onFarming: !feline?.locks
                    ? false
                    : feline.locks?.some(
                          (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.farming,
                      ),
                onSale: feline.locks?.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.auction,
                ),
            };

            allFelineFromUser.push(felineDetails);
        });

        return allFelineFromUser;
    };
}

export default FrontendFelinesService;
